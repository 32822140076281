import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import './App.css';
import detectBrowserLanguage from 'detect-browser-language'    
import ReactGA from 'react-ga';



class site extends Component{
    componentDidMount(){
        document.title = "aozame interactive"
        ReactGA.initialize('UA-168593217-1');
        ReactGA.pageview('Home');
    }

    constructor(props){
        super(props);
        this.state = {
            currentLanguage: '',
            currentSection: 1,
        }
        this.setLanguage = this.setLanguage.bind(this)
        this.setSection = this.setSection.bind(this)
        //"en", "jp(ja)", "fr", "de", "ru", "cn(zh)", "vn(vi)", "kr(ko)"
        if (detectBrowserLanguage().slice(0,2) === "ja" && this.state.currentLanguage === ""){
            this.state.currentLanguage = "jp";
            ReactGA.event({
              category: 'Language',
              action: 'Default Language',
              value: "jp"
            });
        } else if (this.state.currentLanguage === "") {
            this.state.currentLanguage = "en";
            ReactGA.event({
              category: 'Language',
              action: 'Default Language',
              value: "en"
            });
        }
    }
    
    setSection(section){
        this.setState ({
            currentSection: section
        });
        window.scrollTo(0, 0);
    }

    setLanguage(language){
        this.setState ({
            currentLanguage: language
        });
        if (language === "en"){
            ReactGA.event({
              category: 'Language',
              action: 'Set Language',
              value: "en"
            });
        } else if (language === "jp"){
            ReactGA.event({
              category: 'Language',
              action: 'Set Language',
              value: "jp"
            });
        }
    }
    
    render() {
        //Localized Strings
        let title = {en: "aozame interactive",
                     jp: "アオザメインタラクティブ"};
        let contentShown;      
        if (this.state.currentSection === 1){
            contentShown = <Home
            currentLanguage = {this.state.currentLanguage}/>;
            ReactGA.pageview('Home');
        } else if (this.state.currentSection === 2) {
            contentShown = <About
            currentLanguage = {this.state.currentLanguage}
            setSection = {this.setSection}/>;
            ReactGA.pageview('About');
        } else if (this.state.currentSection === 3) {
            contentShown = <Games
            currentLanguage = {this.state.currentLanguage}
            setSection = {this.setSection}/>;
            ReactGA.pageview('Games');
        } else if (this.state.currentSection === 4) {
            contentShown = <Contact
            currentLanguage = {this.state.currentLanguage}
            setSection = {this.setSection}/>;
            ReactGA.pageview('Contact');
        } 
        
        return (
            <div className="main">
                <MetaTags>
                    <title>{title[this.state.currentLanguage]}</title>
                    <meta name="apple-mobile-web-app-capable" content="yes"/>
                    <meta name="apple-mobile-web-app-title" content="aozame interactive" />   
                    <meta name="application-name" content="aozame interactive" />
                    <meta name="description" content="The official website of Aozame Interactive" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                </MetaTags>
                <Header
                    currentLanguage = {this.state.currentLanguage}
                    setLanguage = {this.setLanguage}
                />
                {contentShown}
                <Navigation
                    language = {this.state.currentLanguage}
                    setSection = {this.setSection}
                />
            </div>
        );
    }
}

class Header extends Component{
    constructor(props){
        super(props);
        this.state = {
            value: ''
        }
    }

    render() {
        return (
            <div id="header">
                <CountryButton
                    currentLanguage = {this.props.currentLanguage}
                    setLanguage = {this.props.setLanguage}
                />
            </div>
        );
    }
}

class Footer extends Component{
    constructor(props){
        super(props);
        this.state = {
            value: ''
        }
    }

    render() {
        let footerText = {en: "Privacy Policy",
                          jp: "プライバシーポリシー"};
        let footerText2 = {en: "Terms of Use",
                           jp: "利用規約"};
        let footerLink = {en: "privacy.html",
                          jp: "privacy_jp.html"};
        let footerLink2 = {en: "tou.html",
                           jp: "tou_jp.html"};
        return (
            <div id="footer">
                <span className="copyright">&copy;</span>2023 Aozame Interactive<br/>
                <ReactGA.OutboundLink
                    eventLabel="View Privacy Policy"
                    to={footerLink[this.props.currentLanguage]}
                >
                {footerText[this.props.currentLanguage]}
                </ReactGA.OutboundLink>・ 
                <ReactGA.OutboundLink
                    eventLabel="View ToU"
                    to={footerLink2[this.props.currentLanguage]}
                >
                {footerText2[this.props.currentLanguage]}
                </ReactGA.OutboundLink>
            </div>
        );
    }
}

class Home extends Component{
    constructor(props){
        super(props);
        this.state = {
            value: ''
        }
    }

    render() {
        const mainLogo = require('./components/img/logos/' +this.props.currentLanguage +'.png')
        return (
            <div id="home">
                    <div id="home-logo">
                        <img src={mainLogo} className="main-logo" alt="Aozame Interactive"/>
                    </div>
                <div id="home-footer">
                    <Footer
                        currentLanguage = {this.props.currentLanguage}
                    />
                </div>
            </div>
        );
    }
}

class About extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setSection = this.setSection.bind(this);
    }

    setSection(){
        this.props.setSection(1);
    }

    render() {        
        let title = {en: "about us",
                     jp: "会社概要"};
        let content = {en: "aozame interactive is a small studio based out of Vancouver, Canada. <br> <br> We focus on creating fun, fair, and engaging experiences for mobile.",
                       jp: "アオザメインタラクティブはバンクーバー（カナダ）にある小さいスタジオです。<br> 良心的で、刺激的なゲームを作るを焦点を当ている。"};
        let nameGrant = {en: "Grant Wall",
                         jp: "ウーオル・グラント"};
        let bioGrant = {en: "An avid gamer and fan of both MMORPG's as well as narrative-driven games, Grant had his first experience with game design well over a decade ago, creating personal projects in GameMaker as a young child. <br><br> Since that time, with more than 10 years of experience in the gaming industry, he's had the opportunity to work on a number of titles, over multiple platforms, in a variety of different roles.<br><br>In addition to his current community-centered work on AAA titles, he's started creating games once again with a focus crafting on fun and engaging experiences on mobile.",
                        jp: "若い頃からMMORPGとナラティブゲームを大ファンで、最初ゲームデザイン体験は十年以上前にGameMakerでゲームを作りました。<br><br>その時から、ゲーム業界で十年以上の体験が持って、種々の役で数のプラットフォームに色々タイトルで働いた体験を持っています。<br><br>現在のAAAゲームのコミュニティマネジャーでの役割に加えて、最近に面白くて楽しい携帯のゲーム体験を作ることに専念しています。"};
        let linksGrant ={website: "https://grantwall.com",
                         twitter: "https://twitter.com/grantwall",
                         instagram: "https://instagram.com/grantwall",
                         linkedin: "https://www.linkedin.com/in/grantwall/"}
        let nameLam = {en: "Lam Kwan",
                       jp: "クワン・ラム"};
        

        const mainLogo = require('./components/img/logos/' +this.props.currentLanguage +'.png')
        
        return (
            <div id="about">
                <div className="content">
                    <div id="logo">
                        <button onClick = {this.setSection}>
                            <img src={mainLogo} className="main-logo" alt="Aozame Interactive"/>
                        </button>
                    </div>
                    <div className="title">
                        {title[this.props.currentLanguage]}
                    </div>
                    <div id="about-box">
                       <div className="about-text" dangerouslySetInnerHTML={{__html: content[this.props.currentLanguage]}}></div>
                    </div>
                    <AboutBio
                        currentLanguage = {this.props.currentLanguage}
                        avatar = "grant"
                        name = {nameGrant}
                        description = {bioGrant}
                        links = {linksGrant}
                        float = "left"
                        id = "1"
                    />
                    
                </div>
                <Footer
                    currentLanguage = {this.props.currentLanguage}
                />
            </div>
        );
    }
}

class AboutBio extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }
    
    playVideo1() {
        this.refs.vidRef1.play();
    }
    
    playVideo2() {
        this.refs.vidRef2.play();
    }

    render() {       
        const avatarImg = require('./components/img/bioavatar/avatar_' + this.props.avatar +'.jpg')
        const avatarVideo = require('./components/videos/bioavatar/avatar_' + this.props.avatar +'.mp4')
    
        let avatarFloat;
        let textFloat;
        if (this.props.float === "right"){
            avatarFloat = "bio-image avatar-right"
            textFloat = "bio-text text-right"
        } else {
            avatarFloat = "bio-image avatar-left"
            textFloat = "bio-text text-left"
        }
        
        let bioLinks = [];

        Object.keys(this.props.links).map((key, index) => ( 
            bioLinks.push(
                <BioLink
                    key = {"link"+key}
                    link = {this.props.links[key]}
                    type = {key}
                />
            )
        ))
        
        const aboutClass = "about-bio bio-" + this.props.id
        
        return (
            <div className={aboutClass}>
                <div className={avatarFloat}>
                    <div className="avatars_png">
                        <img src={avatarImg} className="avatar_image" alt="Avatar"/>
                    </div>
                    <div className="avatars">
                        <video ref="vidRef1" className="avatar_video" onMouseOver={this.playVideo1.bind(this)}>
                          <source src={avatarVideo} type="video/mp4"/>
                        </video>
                    </div>
                </div>
                <div className={textFloat}>
                    <div className="bio-title">
                        {this.props.name[this.props.currentLanguage]}
                    </div>
                    <div className="bio-description" dangerouslySetInnerHTML={{__html: this.props.description[this.props.currentLanguage]}}>
                    </div>
                    <div className="bio-buttons">
                        {bioLinks}
                    </div>
                </div>         
            </div>
        );
    }
}

class BioLink extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        const linkIcon = require('./components/img/social/' + this.props.type +'.png')
        const analyticsEventLabel = 'About -> ' + this.props.link
        return (
            <ReactGA.OutboundLink
                    eventLabel={analyticsEventLabel}
                    to={this.props.link}
                >
               <img src={linkIcon} width="30px" alt="icon"/>
            </ReactGA.OutboundLink>
        );
    }
}

class Games extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setSection = this.setSection.bind(this);
    }

    setSection(){
        this.props.setSection(1);
    }
    
    render() {
        let title = {en: "our works",
                     jp: "会社創作"};
        const mainLogo = require('./components/img/logos/' +this.props.currentLanguage +'.png')
        let bannerUniversalApp = {en: "Game (iOS/Android)",
                                  jp: "ゲーム (iOSとAndroid)"};
        let banneriOSApp = {en: "Game (iOS)",
                                  jp: "ゲーム (iOS)"};
        let bannerStickers = {en: "iMessage Stickers",
                              jp: "iMessageステッカー"};
        // eslint-disable-next-line
        let bannerUnavailable = {en: "Currently Unavailable",
                                 jp: "入手不可能"}; 
        let bannerInDevelopment = {en: "In Development",
                                 jp: "開発中"}; 
        return (
            <div id="games">
                <div className="content">
                    <div id="logo">
                        <button onClick = {this.setSection}>
                            <img src={mainLogo} className="main-logo" alt="Aozame Interactive"/>
                        </button>
                    </div>
                    <div className="title">
                        {title[this.props.currentLanguage]}
                    </div>
                    <FeatureGame
                        game = "nekojima"
                        bannerString = {bannerUniversalApp}
                        currentLanguage = {this.props.currentLanguage}
                        link= "https://nekojima.aozame.com"
                    />
                    <div id="other-games">
                        <Game
                            game = "derpy"
                            bannerString = {bannerStickers}
                            currentLanguage = {this.props.currentLanguage}
                            link = "https://apps.apple.com/us/app/derpy-the-shark-dog/id1466197013"
                        />
                        <Game
                            game = "sharkfriends"
                            bannerString = {bannerStickers}
                            currentLanguage = {this.props.currentLanguage}
                            link = "https://apps.apple.com/us/app/shark-friends/id1434643465"
                        />
                                          
                      
                    </div>
                </div>
                <Footer
                    currentLanguage = {this.props.currentLanguage}
                />
            </div>
        );
    }
}

class FeatureGame extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        const banner = require('./components/img/gamebanners/' + this.props.currentLanguage + '/features/' + this.props.game + '.png')
        const analyticsEventLabel = "Works -> " + this.props.link
        var sectionStyle = {
            width: "100%",
            height: "100%",
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            top: "0px"
        };
        return (
            <div className="game-box-holder">
                <ReactGA.OutboundLink
                    eventLabel={analyticsEventLabel}
                    to={this.props.link}
                    target="_blank"
                >
                    <div className="feature game-box">
                        <div className="game-bg" style={sectionStyle}>
                        </div>
                        <div className="game-banner">
                            {this.props.bannerString[this.props.currentLanguage]}
                        </div>
                    </div>
                </ReactGA.OutboundLink>
            </div>
        );
    }
}

class Game extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        const banner = require('./components/img/gamebanners/' + this.props.currentLanguage + '/' + this.props.game + '.png') 
        const analyticsEventLabel = "Works -> " + this.props.link
        var sectionStyle = {
            width: "100%",
            height: "100%",
            backgroundImage: `url(${banner})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "absolute",
            top: "0px"
        }; 
        if (this.props.link === "#"){
            
        } 
        return (
            <div className="game-box-holder">
                <ReactGA.OutboundLink
                    eventLabel={analyticsEventLabel}
                    to={this.props.link}
                    target="_blank"
                >
                    <div className="game-box">
                        <div className="game-bg" style={sectionStyle}>
                        </div>
                        <div className="game-banner">
                            {this.props.bannerString[this.props.currentLanguage]}
                        </div>
                    </div>
                </ReactGA.OutboundLink>
            </div>
        );
    }
}

class Contact extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setSection = this.setSection.bind(this);
    }

    setSection(){
        this.props.setSection(1);
    }

    render() {      
        let title = {en: "contact",
                     jp: "お問い合わせ"};
        let subtitle1 = {en: "social",
                     jp: "ＳＮＳ"};
        let subtitle2 = {en: "press inquiries",
                     jp: "報道機関お問い合わせ"};
        let subtitle3 = {en: "need help?",
                     jp: "サポート"};
        let content = {en: "Looking to get in touch?<br><br>Reach out via our social channels, or drop us a message using the emails below. ",
                       jp: "連絡を取り合いたい？<br>SNSで連絡するやメールを送ってくれてください。"};

        let mailPress = {en: 'mailto:press@aozame.com?subject=Press Inquiry',
                     jp: 'mailto:press@aozame.com?subject=報道機関お問い合わせ'}
        let mailHelp = {en: 'mailto:help@aozame.com?subject=Help Request',
                     jp: 'mailto:help@aozame.com?subject=サポート リクエスト'}
        
        const mainLogo = require('./components/img/logos/' +this.props.currentLanguage +'.png')
        
        return (
            <div id="contact">
                <div id="content" className="content">
                    <div id="logo">
                        <button onClick = {this.setSection}>
                            <img src={mainLogo} className="main-logo" alt="Aozame Interactive"/>
                        </button>
                    </div>
                    <div className="title">
                        {title[this.props.currentLanguage]}
                    </div>
                    <div id="contact-box">
                       <div className="about-text" dangerouslySetInnerHTML={{__html: content[this.props.currentLanguage]}}></div>
                    </div>
                    <div id="contact-content">
                        <div className="subtitle sub-1">
                            {subtitle1[this.props.currentLanguage]}
                        </div>
                        <div id="social-media">
                            <ContactSocial
                                type="facebook"
                                link="https://www.facebook.com/aozameinteractive"
                            />
                            <ContactSocial
                                type="twitter"
                                link="https://twitter.com/aozamegames"
                            />
                            <ContactSocial
                                type="instagram"
                                link="https://instagram.com/aozameinteractive"
                            />
                            <ContactSocial
                                type="linkedin"
                                link="https://linkedin.com/company/aozame"
                            />
                        </div>
                        <div className="subtitle">
                            {subtitle2[this.props.currentLanguage]}
                        </div>
                        <div id="contact-box" className="contact-1">
                            <div className="about-text">
                                <ReactGA.OutboundLink
                                    eventLabel="Contact -> Email Press"
                                    to={mailPress[this.props.currentLanguage]}
                                    target="_blank"
                                >
                                    press@aozame.com
                                </ReactGA.OutboundLink>
                            </div>
                        </div>
                        <div className="subtitle">
                            {subtitle3[this.props.currentLanguage]}
                        </div>
                        <div id="contact-box" className="contact-2">
                            <div className="about-text">
                                <ReactGA.OutboundLink
                                    eventLabel="Contact -> Email Help"
                                    to={mailHelp[this.props.currentLanguage]}
                                    target="_blank"
                                >
                                    help@aozame.com
                                </ReactGA.OutboundLink>
                            </div>
                        </div>  
                    </div>
                </div>
                <Footer
                    currentLanguage = {this.props.currentLanguage}
                />
            </div>
        );
    }
}

class ContactSocial extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        const linkIcon = require('./components/img/social/' + this.props.type +'.png')
        const analyticsEventLabel = "Contact -> " + this.props.link
        return (
                <ReactGA.OutboundLink
                    eventLabel={analyticsEventLabel}
                    to={this.props.link}
                    target="_blank"
                >
                    <img src={linkIcon} width="80px" alt="link"/>
                </ReactGA.OutboundLink>
        );
    }
}

class Navigation extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        let stringEn = {1: "home",
                        2: "about us",
                        3: "our works",
                        4: "contact"};
        let stringJp = {1: "ホーム",
                        2: "会社概要",
                        3: "会社創作",
                        4: "お問い合わせ"};  
        var navItems = [];
        for (var i = 1; i < Object.keys(stringEn).length+1; i++) {
            if (this.props.language === "en"){
                navItems.push(
                    <NavItem
                    key = {"nav"+i}
                    string = {stringEn[i]}
                    icon = {i}
                    setSection = {this.props.setSection}
                    />
                )
            } else {
                navItems.push(
                    <NavItem
                    key = {"nav"+i}
                    string = {stringJp[i]}
                    icon = {i}
                    setSection = {this.props.setSection}
                    />
                )
            }
        }
        return (
            <div id="navigation">
                {navItems}
            </div>
        );
    }
}

class NavItem extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setSection = this.setSection.bind(this);
    }

    setSection(){
        this.props.setSection(this.props.icon);
    }


    render() {
        const navIcon = require('./components/img/navicons/' + this.props.icon + '.png')
        const navClass = "nav-circle nav-" + this.props.icon;
        return (
            <div className={navClass}>
                <button onClick = {this.setSection}>
                    <div className="nav-icon"><img src={navIcon} className="icon" alt="icon"/></div>
                    <div className="nav-title">{this.props.string}</div>
                </button>
            </div>
        );
    }
}

class CountryButton extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setCountry = this.setCountry.bind(this);
    }

    setCountry(){
        if (this.props.currentLanguage === "en"){
            this.props.setLanguage("jp");
        } else {
            this.props.setLanguage("en");
        }
    }

    render() {
        let languageString;
        if (this.props.currentLanguage === "en"){
            languageString = "日本語";
        } else {
            languageString = "English";
        }
        return (
            <button id="country-button" onClick = {this.setCountry}>
                <div id="language-toggle">
                    {languageString}
                </div>
            </button>
        );
    }
}

export default site;
